import React, { useState, useEffect, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  InputBase,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  ClickAwayListener,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ProductModal from "./product/ProductModal";
import { fetchProductData } from "../api/service";

const SearchBar = () => {
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Use React Query to fetch products with proper configuration
  const { data, isLoading, isError } = useQuery({
    queryKey: ["products"],
    queryFn: () => fetchProductData({ page: 1, limit: 1000 }), // Adjust limit as needed
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 10 * 60 * 1000, // 10 minutes
  });

  // Memoize products to prevent unnecessary re-renders
  const products = useMemo(() => {
    return data?.products || [];
  }, [data]);

  // Memoize filtered suggestions
  const filteredSuggestions = useMemo(() => {
    if (!searchValue || !products.length) return [];

    return products.filter(
      (product) =>
        product.brandName.toLowerCase().includes(searchValue.toLowerCase()) ||
        product.genericName.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [searchValue, products]);

  // Update suggestions when filtered results change
  useEffect(() => {
    setSuggestions(filteredSuggestions);
    setShowSuggestions(!!searchValue && filteredSuggestions.length > 0);
  }, [filteredSuggestions, searchValue]);

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSuggestionClick = (product) => {
    setSearchValue(product.brandName);
    setShowSuggestions(false);
    setSelectedProduct(product);
  };

  const handleSearch = () => {
    if (!searchValue || !products.length) return;

    const matchingProduct = products.find(
      (product) =>
        product.brandName.toLowerCase() === searchValue.toLowerCase() ||
        product.genericName.toLowerCase() === searchValue.toLowerCase()
    );

    if (matchingProduct) {
      setSelectedProduct(matchingProduct);
    }
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Error loading products. Please try again later.</div>;
  }

  return (
    <>
      <ClickAwayListener onClickAway={() => setShowSuggestions(false)}>
        <Box
          className="search-bar"
          sx={{ border: "1px solid #215d66", margin: "10px 0", width: "100%" }}
        >
          <InputBase
            placeholder="Search by brand or generic name"
            inputProps={{ "aria-label": "search" }}
            className="search-input"
            value={searchValue}
            onChange={handleInputChange}
            onKeyPress={(e) => e.key === "Enter" && handleSearch()}
            sx={{ ml: 1, flex: 1 }}
          />
          <IconButton
            type="submit"
            aria-label="search"
            className="search-button"
            onClick={handleSearch}
          >
            <SearchIcon />
          </IconButton>

          {showSuggestions && suggestions.length > 0 && (
            <Paper
              elevation={3}
              sx={{
                position: "absolute",
                top: "100%",
                left: 0,
                right: 0,
                zIndex: 1,
                mt: 1,
                maxHeight: "300px",
                overflow: "auto",
              }}
            >
              <List>
                {suggestions.map((product) => (
                  <ListItem
                    key={product.id}
                    button
                    onClick={() => handleSuggestionClick(product)}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                  >
                    <ListItemText
                      primary={product.brandName}
                      secondary={product.genericName}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}
        </Box>
      </ClickAwayListener>

      {selectedProduct && (
        <ProductModal
          product={selectedProduct}
          handleClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default SearchBar;
