import React from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";

const CategoryPage = () => {
  const { categoryId } = useParams();

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {categoryId
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")}
      </Typography>
      
    </Box>
  );
};

export default CategoryPage;
