import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./ProductModal.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HOST_URL } from "../../config/Url";
import { QRCodeSVG } from "qrcode.react";

const ProductModal = ({ product, handleClose }) => {
  const navigate = useNavigate();
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [pdfDialogOpen, setPdfDialogOpen] = useState(false);

  if (!product) {
    return null;
  }

  const handlePdfOpen = (pdf) => {
    setSelectedPdf(pdf);
    setPdfDialogOpen(true);
  };

  const handlePdfClose = () => {
    setPdfDialogOpen(false);
    setSelectedPdf(null);
  };

  const handleMoreInfoClick = () => {
    navigate(`/product/${product.id}`);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const imageFiles =
    product.fileResources?.filter((file) =>
      ["png", "jpg", "jpeg"].includes(file.fileType?.toLowerCase())
    ) || [];

  let pdfFiles =
    product.fileResources?.filter((file) =>
      ["pdf"].includes(file.fileType?.toLowerCase())
    ) || [];

  pdfFiles = pdfFiles.length > 0 ? [pdfFiles[pdfFiles.length - 1]] : [];

  return (
    <>
      <Modal
        open={!!product}
        onClose={handleClose}
        aria-labelledby="product-modal-title"
        className="product-modal"
      >
        <Box
          className="modal-box"
          sx={{
            width: "90%",
            maxWidth: "800px",
            maxHeight: "90vh",
            overflow: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <IconButton
            className="close-button"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>

          <Grid container spacing={3}>
            {/* Left side - Image */}
            <Grid item xs={12} md={5}>
              {imageFiles.length > 0 ? (
                <Box sx={{ width: "100%", mb: 2 }}>
                  <Slider {...sliderSettings}>
                    {imageFiles.map((image, index) => (
                      <div key={index}>
                        <img
                          src={`${HOST_URL}/${image.filePath}`}
                          alt={`${product.brandName} - ${index + 1}`}
                          style={{
                            width: "100%",
                            height: "300px",
                            objectFit: "contain",
                            backgroundColor: "#f0f0f0",
                          }}
                        />
                      </div>
                    ))}
                  </Slider>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: "300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  <Typography>No images available</Typography>
                </Box>
              )}
            </Grid>

            {/* Right side - Product Features Table */}
            <Grid item xs={12} md={7}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Product Features:
              </Typography>
              <Box sx={{ border: "1px solid #ccc", borderRadius: 1 }}>
                <Table
                  sx={{
                    border: "1px solid #ccc",
                    "& th, & td": {
                      border: "1px solid #ccc", // Add borders to all cells
                    },
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        sx={{ fontWeight: "bold", width: "40%" }}
                      >
                        BRAND Name
                      </TableCell>
                      <TableCell>{product.brandName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: "bold" }}>
                        Generic Name
                      </TableCell>
                      <TableCell>{product.genericName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: "bold" }}>
                        Manufacturer
                      </TableCell>
                      <TableCell>{product.manufacturer}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: "bold" }}>
                        Formulation
                      </TableCell>
                      <TableCell>{product.formulation}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: "bold" }}>
                        Available Strength
                      </TableCell>
                      <TableCell>{product.strength}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: "bold" }}>
                        Generic Brand
                      </TableCell>
                      <TableCell>{product.genericBrand}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              {/* Mail Us and WhatsApp Section */}
              <Box sx={{ mt: 3, border: "1px solid #ccc" }}>
                <Grid container>
                  {/* Mail Us Column */}
                  <Grid
                    item
                    xs={6}
                    sx={{
                      borderRight: "1px solid #ccc",
                      "& .header": {
                        bgcolor: "#1f3864",
                        color: "white",
                        p: 1,
                        textAlign: "center",
                      },
                    }}
                  >
                    <Typography variant="subtitle1" className="header">
                      Mail Us
                    </Typography>
                    <Box sx={{ p: 2 }}>
                      <Link
                        href="mailto:info@sarpharma-international.com"
                        sx={{
                          display: "block",
                          color: "#0000EE",
                          textDecoration: "underline",
                          "&:hover": { color: "#0000EE" },
                        }}
                      >
                        info@sarpharma-international.com
                      </Link>
                      <Link
                        href="mailto:export.sarpharma@gmail.com"
                        sx={{
                          display: "block",
                          color: "#0000EE",
                          textDecoration: "underline",
                          "&:hover": { color: "#0000EE" },
                        }}
                      >
                        export.sarpharma@gmail.com
                      </Link>
                    </Box>
                  </Grid>

                  {/* WhatsApp Column */}
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        bgcolor: "#1f3864",
                        color: "white",
                        p: 1,
                        textAlign: "center",
                      }}
                    >
                      Connect to WhatsApp for a quick response
                    </Typography>
                    <Box
                      sx={{
                        p: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <QRCodeSVG
                        value="https://wa.me/+8801886212601"
                        size={120}
                        level="H"
                        includeMargin={true}
                        imageSettings={{
                          src: "/assets/images/whatsapp_logo.png",
                          x: undefined,
                          y: undefined,
                          height: 24,
                          width: 24,
                          excavate: true,
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              {/* Product Description */}
              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  sx={{ bgcolor: "#1f3864", color: "white", p: 1 }}
                >
                  Product Description
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  {product.description}
                </Typography>

                {pdfFiles.map((pdf, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Button
                      variant="outlined"
                      startIcon={<VisibilityIcon />}
                      onClick={() =>
                        handlePdfOpen(`${HOST_URL}/${pdf.filePath}`)
                      }
                      color="primary"
                      fullWidth
                    >
                      See Details
                    </Button>
                  </Grid>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Dialog
        open={pdfDialogOpen}
        onClose={handlePdfClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{product.brandName}</DialogTitle>
        <DialogContent>
          {selectedPdf && (
            <iframe
              src={`${selectedPdf}#toolbar=0`}
              width="100%"
              height="600px"
              title="PDF Viewer"
              frameBorder="0"
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProductModal;
